module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mitech","short_name":"mitech","theme_color":"#0F334D","background_color":"#ffffff","display":"standalone","scope":"/","start_url":"/","icon":"src/assets/images/favicon.png","icons":[{"src":"/favicon.png","sizes":"72x72","type":"image/png"},{"src":"/favicon.png","sizes":"96x96","type":"image/png"},{"src":"/favicon.png","sizes":"128x128","type":"image/png"},{"src":"/favicon.png","sizes":"144x144","type":"image/png"},{"src":"/favicon.png","sizes":"152x152","type":"image/png"},{"src":"/favicon.png","sizes":"192x192","type":"image/png"},{"src":"/favicon.png","sizes":"384x384","type":"image/png"},{"src":"/favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acf9c7a62ea5e7a4d5f9b615d2178065"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/mitech/src/intl","languages":["en","ar"],"defaultLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html"],"useClassNames":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
