// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-consultation-request-jsx": () => import("./../../../src/pages/consultation-request.jsx" /* webpackChunkName: "component---src-pages-consultation-request-jsx" */),
  "component---src-pages-consultation-request-technical-advice-jsx": () => import("./../../../src/pages/consultation-request-technical-advice.jsx" /* webpackChunkName: "component---src-pages-consultation-request-technical-advice-jsx" */),
  "component---src-pages-consultation-request-tecknical-follow-up-jsx": () => import("./../../../src/pages/consultation-request-tecknical-follow-up.jsx" /* webpackChunkName: "component---src-pages-consultation-request-tecknical-follow-up-jsx" */),
  "component---src-pages-consultation-request-training-and-qualifications-jsx": () => import("./../../../src/pages/consultation-request-training-and-qualifications.jsx" /* webpackChunkName: "component---src-pages-consultation-request-training-and-qualifications-jsx" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-elements-accordion-js": () => import("./../../../src/pages/elements/accordion.js" /* webpackChunkName: "component---src-pages-elements-accordion-js" */),
  "component---src-pages-elements-box-large-image-js": () => import("./../../../src/pages/elements/box-large-image.js" /* webpackChunkName: "component---src-pages-elements-box-large-image-js" */),
  "component---src-pages-elements-button-js": () => import("./../../../src/pages/elements/button.js" /* webpackChunkName: "component---src-pages-elements-button-js" */),
  "component---src-pages-elements-client-logo-js": () => import("./../../../src/pages/elements/client-logo.js" /* webpackChunkName: "component---src-pages-elements-client-logo-js" */),
  "component---src-pages-elements-flexible-image-slider-js": () => import("./../../../src/pages/elements/flexible-image-slider.js" /* webpackChunkName: "component---src-pages-elements-flexible-image-slider-js" */),
  "component---src-pages-elements-gradation-js": () => import("./../../../src/pages/elements/gradation.js" /* webpackChunkName: "component---src-pages-elements-gradation-js" */),
  "component---src-pages-elements-lists-js": () => import("./../../../src/pages/elements/lists.js" /* webpackChunkName: "component---src-pages-elements-lists-js" */),
  "component---src-pages-elements-message-box-js": () => import("./../../../src/pages/elements/message-box.js" /* webpackChunkName: "component---src-pages-elements-message-box-js" */),
  "component---src-pages-elements-progress-bar-js": () => import("./../../../src/pages/elements/progress-bar.js" /* webpackChunkName: "component---src-pages-elements-progress-bar-js" */),
  "component---src-pages-elements-progress-circle-js": () => import("./../../../src/pages/elements/progress-circle.js" /* webpackChunkName: "component---src-pages-elements-progress-circle-js" */),
  "component---src-pages-elements-slider-infinite-services-details-custom-placeholder-jsx": () => import("./../../../src/pages/elements/slider-infinite/services-details/custom-placeholder.jsx" /* webpackChunkName: "component---src-pages-elements-slider-infinite-services-details-custom-placeholder-jsx" */),
  "component---src-pages-elements-slider-infinite-services-details-slider-jsx": () => import("./../../../src/pages/elements/slider-infinite/services-details/slider.jsx" /* webpackChunkName: "component---src-pages-elements-slider-infinite-services-details-slider-jsx" */),
  "component---src-pages-elements-slider-infinite-slideranimated-js": () => import("./../../../src/pages/elements/slider-infinite/slideranimated.js" /* webpackChunkName: "component---src-pages-elements-slider-infinite-slideranimated-js" */),
  "component---src-pages-elements-social-networks-js": () => import("./../../../src/pages/elements/social-networks.js" /* webpackChunkName: "component---src-pages-elements-social-networks-js" */),
  "component---src-pages-elements-tabs-js": () => import("./../../../src/pages/elements/tabs.js" /* webpackChunkName: "component---src-pages-elements-tabs-js" */),
  "component---src-pages-elements-team-member-js": () => import("./../../../src/pages/elements/team-member.js" /* webpackChunkName: "component---src-pages-elements-team-member-js" */),
  "component---src-pages-elements-testimonials-js": () => import("./../../../src/pages/elements/testimonials.js" /* webpackChunkName: "component---src-pages-elements-testimonials-js" */),
  "component---src-pages-elements-typed-text-js": () => import("./../../../src/pages/elements/typed-text.js" /* webpackChunkName: "component---src-pages-elements-typed-text-js" */),
  "component---src-pages-engineering-designs-interior-designs-jsx": () => import("./../../../src/pages/engineering-designs/interior-designs.jsx" /* webpackChunkName: "component---src-pages-engineering-designs-interior-designs-jsx" */),
  "component---src-pages-engineering-designs-jsx": () => import("./../../../src/pages/engineering-designs.jsx" /* webpackChunkName: "component---src-pages-engineering-designs-jsx" */),
  "component---src-pages-engineering-designs-modifications-reahbititions-jsx": () => import("./../../../src/pages/engineering-designs/modifications-reahbititions.jsx" /* webpackChunkName: "component---src-pages-engineering-designs-modifications-reahbititions-jsx" */),
  "component---src-pages-engineering-designs-new-design-jsx": () => import("./../../../src/pages/engineering-designs/new-design.jsx" /* webpackChunkName: "component---src-pages-engineering-designs-new-design-jsx" */),
  "component---src-pages-engineering-designs-rehabilitation-heritage-buildings-jsx": () => import("./../../../src/pages/engineering-designs/Rehabilitation-heritage-buildings.jsx" /* webpackChunkName: "component---src-pages-engineering-designs-rehabilitation-heritage-buildings-jsx" */),
  "component---src-pages-general-technical-advisory-jsx": () => import("./../../../src/pages/general-technical-advisory.jsx" /* webpackChunkName: "component---src-pages-general-technical-advisory-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-study-request-interiordesign-jsx": () => import("./../../../src/pages/study-request-interiordesign.jsx" /* webpackChunkName: "component---src-pages-study-request-interiordesign-jsx" */),
  "component---src-pages-study-request-jsx": () => import("./../../../src/pages/study-request.jsx" /* webpackChunkName: "component---src-pages-study-request-jsx" */),
  "component---src-pages-study-request-modify-project-jsx": () => import("./../../../src/pages/study-request-modifyProject.jsx" /* webpackChunkName: "component---src-pages-study-request-modify-project-jsx" */),
  "component---src-pages-study-request-reahbitition-jsx": () => import("./../../../src/pages/study-request-reahbitition.jsx" /* webpackChunkName: "component---src-pages-study-request-reahbitition-jsx" */),
  "component---src-pages-sudy-request-newdesign-jsx": () => import("./../../../src/pages/sudy-request-newdesign.jsx" /* webpackChunkName: "component---src-pages-sudy-request-newdesign-jsx" */),
  "component---src-pages-suggestion-js": () => import("./../../../src/pages/suggestion.js" /* webpackChunkName: "component---src-pages-suggestion-js" */),
  "component---src-pages-technical-advice-jsx": () => import("./../../../src/pages/technical-advice.jsx" /* webpackChunkName: "component---src-pages-technical-advice-jsx" */),
  "component---src-pages-technical-advice-request-jsx": () => import("./../../../src/pages/technical-advice-request.jsx" /* webpackChunkName: "component---src-pages-technical-advice-request-jsx" */),
  "component---src-pages-tecknical-follow-up-jsx": () => import("./../../../src/pages/tecknical-follow-up.jsx" /* webpackChunkName: "component---src-pages-tecknical-follow-up-jsx" */),
  "component---src-pages-training-and-qualifications-jsx": () => import("./../../../src/pages/training-and-qualifications.jsx" /* webpackChunkName: "component---src-pages-training-and-qualifications-jsx" */)
}

